import TimelineEvent from '../timeline_event/timeline_event';
import './timeline.css';

const events = [
    { 
        id: 1, 
        heading: "Mortal Kombat 1",
        subheading: "Unreal 4 | C++ | C# | SQL",
        descriptionGetter: function() {
          return <ul>
            <li>Collaborated with NetherRealm Studios to help with development 
              and post-release support of Mortal Kombat 1.
            </li>
            <li>Created development tools to support design, engineering and QA teams.</li>
            <li>Captured analytic data across all areas of the game to provide 
              the BI team an insight into user behaviour.
            </li>
          </ul>
        },
        year: 2024, 
        image: "images/events/MK1.webp",
        image_alt_text: "Mortal Kombat 1 cover art",
        show_trail: true
    },
    { 
        id: 2, 
        heading: "Lego Star Wars: The Skywalker Saga", 
        subheading: "nTT | C++ | AngelScript",
        descriptionGetter: function() {
          return <ul>
          <li>Worked on the open-world team, crafting quests, puzzles and random events across over 20 hubs.</li>
          <li>Also worked on the UI/Meta team, doing everything from bringing menu wireframes to life, to 
            providing crucial systems to manage Collectables and game/accessibility settings.
          </li>
        </ul>
        },
        year: 2022,
        image: "images/events/LSW.webp",
        image_alt_text: "Lego Star Wars: The Skywalker Saga cover art",
        show_trail: true,
    },
    { 
        id: 3, 
        heading: "Tt Games", 
        subheading: "",
        descriptionGetter: function() {
          return <ul>
          <li>Joined Tt Games as a Junior Gameplay Developer.</li>
        </ul>
        },
        year: 2019,
        image: "images/events/TT.webp",
        image_alt_text: "Tt Games logo",
        show_trail: true,
    },
    { 
        id: 4, 
        heading: "University of South Wales", 
        subheading: "Grade: Distinction",
        year: 2019,
        descriptionGetter: function() {
          return <ul>
            <li>Graduated with a distinction in MComp Computer Games Development.</li>
            <li>Awarded the Certificate of Excellence Prize from the British
            Computer Society.</li>
          </ul>
        },
        image: "images/events/USW.webp",
        image_alt_text: "University of South Wales logo",
        show_trail: false,
    },
  ];
  
  export default function Timeline() {
    const listItems = events.map(event =>
      <li key={event.id}>
        <TimelineEvent 
            heading={event.heading}
            subheading={event.subheading}
            descriptionGetter={event.descriptionGetter}
            year={event.year} 
            image={event.image} 
            image_alt_text={event.image_alt_text}
            show_trail={event.show_trail}/>
      </li>
    );
  
    return (
      <ul>{listItems}</ul>
    );
  }
  