import './timeline_event.css';

export default function TimelineEvent({heading, subheading, descriptionGetter, year, image, image_alt_text, show_trail}) {
    return (
      <div class="timeline_event">
        <div class="event_image">
            <img src={image} alt={image_alt_text}></img>
            <h3>{subheading}</h3>
        </div>
        <div class="year">
            <div class="circle">
              <h1>{year}</h1>
            </div>
            {show_trail && (<div class="trail"/>)}
        </div>
        <div class="event_description">
            <h1>{heading}</h1>
            {descriptionGetter()}
        </div>
      </div>
    );
  }