import './Footer.css';

export default function Footer() {
    return (
      <div id="footer">
        <nav> 
            <a href="http://www.twitter.com/JakeP121"           target="_blank" rel='noreferrer' aria-label="Visit my Twitter profile"><img src="images/gonzocons/twitter-light.svg" alt="Twitter"/></a> 
            <a href="mailto:contact@jakepassmore.com"           target="_blank" rel='noreferrer' aria-label="Send me an email"><img src="images/gonzocons/email-light.svg" alt="Email"/></a>
            <a href="https://www.github.com/JakeP121"           target="_blank" rel='noreferrer' aria-label="Visit my github"><img src="images/gonzocons/github-light.svg" alt="GitHub"/></a> 
            <a href="https://www.linkedin.com/in/JakePassmore"  target="_blank" rel='noreferrer' aria-label="Visit my linked-in profile"><img src="images/gonzocons/linkedin-light.svg" alt="Linked-In"/></a>
        </nav>
                      
        <div>
            <p2>Copyright &copy; Jake Passmore 2024</p2>
        </div>
      </div>
    );
  }