import './Header.css';
import { Component } from 'react';

class HeaderTitle extends Component {
  onClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  render() {
    return (
      <h1 onClick={this.onClick}>Jake Passmore</h1>
    )
  }
}

class GamesButton extends Component {
  onClick = () => {
    window.scrollTo({ top: window.innerHeight * 1.01, left: 0, behavior: 'smooth' });
  };

  render() {
    return (
      <button onClick={this.onClick}>Games</button>
    )
  }
}

export default function Header() {
    return (
      <div id="header">
        <HeaderTitle />

        <nav>
            <GamesButton />
            <a href="https://jakepassmore.com/downloads/CV.pdf">
              <button>CV</button>
            </a>
        </nav>
      </div>
    );
  }