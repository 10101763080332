import './App.css';
import Header from './components/header/Header.js'
import Footer from './components/footer/Footer.js'
import Timeline from './components/timeline/timeline.js';

function App() {
  return (
    <div className="App">
      <Header />
      <div id="main">
        <div id="intro">
          <div>
            <h1>Jake Passmore</h1>
            <h2>Game Programmer</h2>
            <h2>Currently based in North West England</h2>
          </div>
        </div>
        
        <Timeline />
      </div>
      <Footer />
    </div>
  );
}

export default App;
